package de.geomobile.common.portalmodels

import de.geomobile.common.time.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class Position(
    val lastSeen: TimestampStatus,
    val lastLocation: TimestampStatus,
    val fix: Boolean,
    val location: Location? = null
) {
    @Serializable
    data class Location(
        val longitude: Double,
        val latitude: Double,
        val timestamp: LocalDateTime? = null,
        val satellites: Int? = null
    )
}

data class RawLocation(
    val longitude: Float,
    val latitude: Float
)